<template>
  <b-card>
    <div class="privacy-policy">
      <h2>Privacy Policy</h2>

      <p>
        This Privacy Policy outlines how Ophelio.de collects, uses, discloses, and protects your personal information. Please read this policy carefully to understand how we handle your data.
      </p>

      <div class="policy-section">
        <h3>1. Information We Collect:</h3>
        <p>
          We may collect personal information, including but not limited to:
        </p>
        <ul>
          <li>Contact information (name, email address, phone number)</li>
          <li>Billing and payment details</li>
          <li>Usage data (website interactions, product usage)</li>
          <li>Other information relevant to customer surveys and offers</li>
        </ul>
      </div>

      <div class="policy-section">
        <h3>2. How We Use Your Information:</h3>
        <p>
          We use your personal information for the following purposes:
        </p>
        <ul>
          <li>To provide and maintain our products/services</li>
          <li>To process payments and invoices</li>
          <li>To communicate with you about our products/services</li>
          <li>To improve our products/services and customer experience</li>
          <li>To send promotional emails about new products, special offers, or other information we think you may find interesting</li>
        </ul>
      </div>

      <!-- Add other sections following a similar structure -->

      <div class="policy-section">
        <h3>7. Changes to Privacy Policy:</h3>
        <p>
          We reserve the right to update this Privacy Policy at any time. Any changes will be posted on this page. It is your responsibility to review this Privacy Policy periodically.
        </p>
      </div>

      <div class="contact-information">
        <h3>8. Contact Information:</h3>
        <p>
          If you have any questions or concerns about our Privacy Policy, please contact us at
          <span class="contact-info">support@ophelio.de</span>.
        </p>
      </div>

      <div class="footer">
        <p>
          By using our products/services, you consent to the terms of this Privacy Policy.
        </p>
        <p>
          Ophelio.de<br>
          suupport@ophelio.de<br>
        </p>
      </div>
    </div>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue';

export default {
  components: {
    BCard,
  },
  setup() {},
};
</script>

<style scoped>
.privacy-policy {
  font-family: Arial, sans-serif;
}

.policy-section, .contact-information {
  margin-bottom: 20px;
}

.contact-info {
  font-weight: bold;
}

.footer {
  margin-top: 20px;
  font-size: 0.8em;
}
</style>
